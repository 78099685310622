import { format } from 'date-fns';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import _ from 'lodash';
import { lighten } from 'polished';
import React, { useEffect } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from '../styles/colors';
import { PageContext } from '../templates/post';
import { AuthorList } from './AuthorList';

import { daysBetween, getCurrentDate } from '../utils';

import config from '../website-config';

export interface PostCardProps {
  post: PageContext;
  large?: boolean;
}

export const Ribbon = props => {
  return (
    <div css={props.cssStyle} className="ribbon-wrapper">
      <div className="ribbon">{props.text}</div>
    </div>
  );
};

export const PostCard: React.FC<PostCardProps> = ({ post, large = false }) => {
  const date = new Date(post.frontmatter.date);
  // 2018-08-20
  const datetime = format(date, 'yyyy-MM-dd');
  // 20 AUG 2018
  const displayDatetime = format(date, 'dd LLL yyyy');

  let currentDate = getCurrentDate();

  const isRecent = daysBetween(datetime, currentDate) <= 3;
  const isDraft = post.frontmatter.draft;

  return (
    <article
      className={`post-card ${post.frontmatter.image ? '' : 'no-image'} ${
        large ? 'post-card-large' : ''
      }`}
      css={[PostCardStyles, large && PostCardLarge]}
    >
      <div className="post-div" css={PostDiv}>
        {post.frontmatter.image && (
          <Link className="post-card-image-link" css={PostCardImageLink} to={post.fields.slug}>
            <PostCardImage className="post-card-image">
              {post.frontmatter?.image?.childImageSharp?.gatsbyImageData && (
                <GatsbyImage
                  alt={`${post.frontmatter.title} cover image`}
                  style={{ height: '100%' }}
                  placeholder="blurred"
                  image={post.frontmatter.image.childImageSharp.gatsbyImageData}
                />
              )}
            </PostCardImage>
          </Link>
        )}
        <PostCardContent className="post-card-content">
          <PostCardHeaderTop>
            {post.frontmatter.tags && (
              <Link
                className="post-card-primary-tag-link"
                to={`/tags/${_.kebabCase(post.frontmatter.tags[0])}/`}
              >
                <PostCardPrimaryTag className="post-card-primary-tag">
                  {post.frontmatter.tags[0]}
                </PostCardPrimaryTag>
              </Link>
            )}
            <Link className="post-card-header-tag-buffer" to={post.fields.slug}></Link>
          </PostCardHeaderTop>
          <Link className="post-card-content-link" css={PostCardContentLink} to={post.fields.slug}>
            <PostCardHeader className="post-card-header">
              <PostCardTitle className="post-card-title">{post.frontmatter.title}</PostCardTitle>
            </PostCardHeader>
            <PostCardExcerpt className="post-card-excerpt">
              <p>{post.frontmatter.excerpt || post.excerpt}</p>
            </PostCardExcerpt>
          </Link>
          <PostCardMeta className="post-card-meta">
            <AuthorList authors={post.frontmatter.author} tooltip="small" />
            <PostCardBylineContent className="post-card-byline-content">
              <span>
                {post.frontmatter.author.map((author, index) => {
                  return (
                    <React.Fragment key={author.id}>
                      <Link to={`/author/${_.kebabCase(author.id)}/`}>{author.id}</Link>
                      {post.frontmatter.author.length - 1 > index && ', '}
                    </React.Fragment>
                  );
                })}
              </span>
              <span className="post-card-byline-date">
                {/* <time dateTime={datetime}>{displayDatetime}</time>{' '} */}
                {/* <span className="bull">&bull;</span>  */}
                {post.frontmatter.timeToRead} min read
              </span>
            </PostCardBylineContent>
          </PostCardMeta>
        </PostCardContent>
      </div>

      {isRecent && <Ribbon text="NEW" cssStyle={RibbonNew} />}
      {isDraft && <Ribbon text="DRAFT" cssStyle={RibbonDraft} />}
    </article>
  );
};

const PostCardStyles = css`
  position: relative;
  flex: 1 1 350px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 40px;
  padding: 0 20px 40px;
  min-height: 220px;
  max-width: 100%;
  overflow: auto;
  display: flex;
  padding-top: 0.3em;
  /*font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
  'Open Sans', 'Helvetica Neue', sans-serif;*/
  /* border-bottom: 1px solid color(var(--lightgrey) l(+12%)); */
  /* border-bottom: 1px solid ${lighten('0.12', colors.lightgrey)}; */
  background-size: cover;

  @media (prefers-color-scheme: dark) {
    /* border-bottom-color: color(var(--darkmode) l(+8%)); */
    border-bottom-color: ${lighten('0.08', colors.darkmode)};
  }

  div a div div picture img {
    object-fit: contain;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }

  div.post-div:hover + div.ribbon-wrapper {
    transform: translateY(-4px);
  }
`;

const PostCardLarge = css`
  @media (min-width: 795px) {
    flex: 1 1 100%;
    flex-direction: row;
    padding-bottom: 40px;
    min-height: 280px;
    border-top: 0;
    max-width: 100%;

    :not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
    }

    .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-content-link {
      padding: 0 0 0 40px;
    }

    .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }

    div a div img {
      object-fit: cover !important;
    }
  }
`;

const PostCardImageLink = css`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

const PostCardImage = styled.div`
  width: auto;
  height: auto;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
  background: transparent;
`;

const PostDiv = css`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
  max-width: 100%;
  width: 100%;
  /*background: #fff;*/
  height: auto;
  display: table-row;
  :hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  /*a div div picture img {
    object-fit: contain;
  }*/

  :hover {
    transform: translateY(-3px);
  }

  @media (prefers-color-scheme: dark) {
    background: rgb(22 50 86 / 25%);
  }

  div a section p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const PostCardContent = styled.div`
  padding: 1em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .post-card-primary-tag-link {
    width: fit-content;
  }
`;

const PostCardContentLink = css`
  position: relative;
  display: block;
  /* color: var(--darkgrey); */
  color: ${colors.darkgrey};

  :hover {
    text-decoration: none;
  }
`;

const PostCardPrimaryTag = styled.div`
  margin-bottom: -0.75em;
  /* color: var(--blue); */
  color: ${colors.blue};
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

const PostCardHeaderTop = styled.div`
  display: grid;
  grid-template-columns: minmax(20px, auto) 1fr;
`;

const PostCardTitle = styled.h2`
  margin: 0 0 0.4em;
  line-height: 1.15em;
  transition: color 0.2s ease-in-out;
  /*font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
  'Open Sans', 'Helvetica Neue', sans-serif;*/

  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.85);
  }
`;

const PostCardExcerpt = styled.section`
  /*font-family: Georgia, serif;*/

  @media (prefers-color-scheme: dark) {
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)} !important;
  }
`;

const PostCardMeta = styled.footer`
  display: flex;
  align-items: flex-start;
  padding: 0;
`;

const PostCardBylineContent = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  margin: 4px 0 0 10px;
  /* color: color(var(--midgrey) l(+10%)); */
  color: ${lighten('0.1', colors.midgrey)};
  font-size: 1.2rem;
  line-height: 1.4em;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  span {
    margin: 0;
  }

  a {
    /* color: color(var(--darkgrey) l(+20%)); */
    color: ${lighten('0.2', colors.darkgrey)};
    font-weight: 600;
  }

  @media (prefers-color-scheme: dark) {
    a {
      color: rgba(255, 255, 255, 0.75);
    }
  }
`;

const PostCardHeader = styled.header`
  margin: 15px 0 0;
`;

export const StaticAvatar = css`
  display: block;
  overflow: hidden;
  margin: 0 0 0 -6px;
  width: 34px;
  height: 34px;
  border: #fff 2px solid;
  border-radius: 100%;

  @media (prefers-color-scheme: dark) {
    /* border-color: color(var(--darkgrey) l(+2%)); */
    border-color: ${lighten('0.02', colors.darkgrey)};
  }
`;

export const AuthorProfileImage = css`
  display: block;
  width: 100%;
  height: 100%;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: ${lighten('0.1', colors.lightgrey)};
  border-radius: 100%;
  object-fit: cover;

  @media (prefers-color-scheme: dark) {
    background: ${colors.darkmode};
  }
`;

const RibbonNew = css`
  padding: 0em 15px;
  width: 100px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  left: 21px;
  z-index: 10;
  transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

  .ribbon {
    font: bold 15px 'Inter';
    font-family: inherit;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    width: 120px;
    background-color: #3eb0ef;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    color: #fff;
    top: 15px;
    left: -43px;
  }
`;

const RibbonDraft = css`
  padding: 0em 15px;
  width: 100px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  right: 20px;
  z-index: 10;
  transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

  .ribbon {
    font: bold 15px 'Inter';
    font-family: inherit;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    width: 120px;
    background-color: #f00;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    color: #fff;
    top: 15px;
    right: 6px;
  }
`;
